
import React from 'react'
import Navigation from '../../components/common/navigation/Navigation'
import CurrentProject from '../../components/common/plp/currentproject/CurrentProject'
import Projectone from '../../components/common/plp/projectone/Projectone'
import Footer from '../../components/common/footer/Footer'
import { Helmet } from "react-helmet";




const PlpPages = () => {
  return (
    <>
      <Helmet>
        <title>Residential & Commercial Properties In Mumbai, Thane and Goa</title>
        <meta name="description" content="The Dynamix Group is renowned for its well-designed residential and commercial properties in Mumbai, Thane and Goa. Call us to know more!" />
        <meta property="og:title" content="Premium 2 & 3 Bed Residences in Andheri East | Residential Projects near Mumbai International Airport Luma" />
        <meta property="og:site_name" content="Dynamix Group" />
        <meta property="og:url" content="https://www.dynamixgroup.co.in/current-projects/luma" />
        <meta property="og:description" content="Discover Spacious 2 & 3 bhk flats with private balconies in Andheri east at Luma by Dynamix Group. We offer luxury residential projects near mumbai international airport with top notch ameneties." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://www.dynamixgroup.co.in/current-projects/luma#gallery-3" />
        <link rel="canonical" href="https://www.dynamixgroup.co.in/current-projects" />
      </Helmet>
      <Navigation />
      <CurrentProject />
      <Projectone />
      <Footer />
    </>
  )
}

export default PlpPages
