import React from 'react';
// Components
import Navigation from '../../components/common/navigation/Navigation';
import Hero from '../../components/common/amenitiesfeature/hero/Hero';
import BottomNav from '../../components/common/amenitiesfeature/bottomNav/BottomNav';
import Landmark from '../../components/common/amenitiesfeature/landmark/Landmark';
import Info from '../../components/common/amenitiesfeature/info/Info';
import Experience from '../../components/common/amenitiesfeature/experience/Experience';
// import Features from '../../components/common/amenitiesfeature/features/Features';
import Gallery from '../../components/common/amenitiesfeature/gallery/Gallery';
import Walkthrough from '../../components/common/amenitiesfeature/walkthrough/Walkthrough';
// import VirtualTour from '../../components/common/amenitiesfeature/virtualTour/VirtualTour';
// import Exterior from '../../components/common/amenitiesfeature/exterior/Exterior';
import FloorPlan from '../../components/common/amenitiesfeature/floorPlan/FloorPlan';
import Barcode from '../../components/common/amenitiesfeature/barcode/Barcode';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';
import { pdpBottomNavData } from '../../constants/pdpBottomNavData';
import { Helmet } from "react-helmet";





const AmenitiesfeaturePage = () => {
  return (
    <>
      <Helmet>
        <title>Seaside Apartments In Goa | 2 & 3 BHK Homes in Goa | 101 Residences - Dynamix Group</title>
        <meta name="description" content="Discover 101 Residences by Dynamix Group. Experience coastal living at these 2 & 3 BHK residential apartments near Bambolin Beach, Goa." />
        <meta property="og:title" content="Seaside Apartments In Goa | 2 & 3 BHK Homes in Goa | 101 Residences" />
        <meta property="og:site_name" content="Dynamix Group" />
        <meta property="og:url" content="https://www.dynamixgroup.co.in/current-projects/101" />
        <meta property="og:description" content="Discover 101 Residences by Dynamix Group. Experience coastal living at these 2 & 3 BHK residential apartments near Bambolin Beach, Goa." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://www.dynamixgroup.co.in/current-projects/101#gallery-3" />
        {/* <meta name='robots' content='noindex, nofollow' /> */}
      </Helmet>
      <Navigation />
      <Hero />
      <BottomNav pdpBottomNavData={pdpBottomNavData} />
      <Landmark />
      <Info />
      <Experience />
      {/* <Features /> */}
      <Gallery />
      <Walkthrough />
      <FloorPlan />
      <Barcode />
      <Contact />
      <Footer />
    </>
  );
};

export default AmenitiesfeaturePage;
