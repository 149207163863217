import React from 'react';
// Components
import Navigation from '../../components/common/navigation/Navigation';
import Hero from '../../components/common/aldeiadegoa/hero/Hero';
import BottomNav from '../../components/common/aldeiadegoa/bottomNav/BottomNav';
import Landmark from '../../components/common/aldeiadegoa/landmark/Landmark';
import Info from '../../components/common/aldeiadegoa/info/Info';
import Experience from '../../components/common/aldeiadegoa/experience/Experience';
// import Features from '../../components/common/aldeiadegoa/features/Features';
import Gallery from '../../components/common/aldeiadegoa/gallery/Gallery';
import Walkthrough from '../../components/common/aldeiadegoa/walkthrough/Walkthrough';
// import VirtualTour from '../../components/common/aldeiadegoa/virtualTour/VirtualTour';
// import Exterior from '../../components/common/aldeiadegoa/exterior/Exterior';
import FloorPlan from '../../components/common/aldeiadegoa/floorPlan/FloorPlan';
import Barcode from '../../components/common/aldeiadegoa/barcode/Barcode';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';
import { pdpBottomNavData } from '../../constants/pdpBottomNavData';
import { Helmet } from "react-helmet";





const AldeiadeGoaPage = () => {
  return (
    <>
      <Helmet>
        <title>Sea View Apartments In Goa | Flats near Bambolim Beach Goa | Villas In North Goa| Aldeia de Goa</title>
        <meta name="description" content="Discover ready-to-move sea-view apartments in Goa, including sea-facing flats near Bambolim Beach North Goa. Explore luxury villas and seaside apartments in North Goa for the perfect coastal living experience" />
        <meta property="og:title" content="Sea View Apartments In Goa | Flats near Bambolim Beach Goa | Villas In North Goa| Aldeia de Goa" />
        <meta property="og:site_name" content="Dynamix Group" />
        <meta property="og:url" content="https://www.dynamixgroup.co.in/current-projects/aldeiadegoa" />
        <meta property="og:description" content="Discover ready-to-move sea-view apartments in Goa, including sea-facing flats near Bambolim Beach North Goa. Explore luxury villas and seaside apartments in North Goa for the perfect coastal living experience." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://www.dynamixgroup.co.in/current-projects/aldeiadegoa#gallery-1" />

        <link rel="canonical" href="https://www.dynamixgroup.co.in/current-projects/aldeiadegoa" />
      </Helmet>
      <Navigation />
      <Hero />
      <BottomNav pdpBottomNavData={pdpBottomNavData} />
      <Landmark />
      <Info />
      <Experience />
      {/* <Features /> */}
      <Gallery />
      <Walkthrough />
      {/* <VirtualTour /> */}
      {/* <Exterior /> */}
      <FloorPlan />
      <Barcode />
      <Contact />
      <Footer />
    </>
  );
};

export default AldeiadeGoaPage;
